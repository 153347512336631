import { useRef, useEffect } from 'react'
import { FormWrapper as FormWrapperAction } from '@style/components/form'
import { Formik, Form as FormStart } from 'formik'

const FormWrapper = ({ children, initialValues, validation, onSubmit, enableReinitialize, debug, readOnly }) => {
  const ChildContent = children
  const formRef = useRef()
  useEffect(() => {
    if (readOnly) {
      formRef?.current?.querySelectorAll('input, textarea, select').forEach(itm => {
        itm.setAttribute('readonly', true)
      })
    } else {
      formRef?.current?.querySelectorAll('input, textarea, select').forEach(itm => {
        if (itm.hasAttribute('readonly')) {
          itm.setAttribute('readonly', false)
        }
      })
    }
  }, [readOnly])
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validation}
      enableReinitialize={enableReinitialize}
      onSubmit={onSubmit || (() => {})}
    >
      {forms => {
        // just for debug dude
        if (process.env.NODE_ENV === 'development' && debug) {
          if (Object.entries(forms?.errors || {})?.length) {
            console.log('%cFORMIK INVALID VALUE', 'color: red; font-size: 24px; font-weight:bold;')
            console.table(forms.errors)
          }
        }
        return (
          <FormStart>
            <FormWrapperAction readOnly={readOnly} ref={formRef}>
              <ChildContent {...{ ...forms, readOnly }} />
            </FormWrapperAction>
          </FormStart>
        )
      }}
    </Formik>
  )
}

export default FormWrapper
