import Input from '@util/form/input'
import FormWrapper from '@util/form/wrapper'

import {
  Group,
  Label,
  GroupContent,
  FormError as FormErrorStyle,
  FormRadioGroup as FormRadioGroupStye,
  FormTitle as FormTitleStyle,
  InputGroupInput as InputGroupInputStyle,
} from '@/styles/components/form'
import Select from '@util/form/select'
import InputGroupWrapper from '@util/form/input-group'
import FormCheckComponent from '@util/form/check'
import FormRadioComponent from '@util/form/radio'
import FormFileWrapperComponent from '@util/form/file-wrapper'
import FormTextareaComponent from '@util/form/textarea'

/**
 * Component source export
 */
export const FormInputGroup = InputGroupWrapper
export const FormLabel = Label
export const FormGroup = Group
export const FormInput = Input
export const FormSelect = Select
export const InputGroupContent = GroupContent
export const InputGroupInput = InputGroupInputStyle
export const FormRadio = FormRadioComponent
export const FormTextarea = FormTextareaComponent
export const FormTitle = FormTitleStyle
// checkbox and radio
export const FormRadioGroup = FormRadioGroupStye
export const FormCheck = FormCheckComponent
// error handle
export const FormError = FormErrorStyle
// file
export const FormFileWrapper = FormFileWrapperComponent

export default FormWrapper
