import { useEffect, useRef, useState } from 'react'
import TetherComponent from 'react-tether'
import styled from 'styled-components'

const TetherStyle = styled.div`
  background-color: ${props => props.theme.colors.white};
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  ul.dropdown {
    margin: 0;
    padding: 0;
    list-style: none;
    padding-top: 10px;
    padding-bottom: 10px;
    li {
      color: ${props => props.theme.colors.dark};
      cursor: pointer;
      padding: 8px 15px;
      display: flex;
      align-items: center;
      &:hover {
        background-color: ${props => props.theme.colors.grey8};
      }
      i {
        font-size: 18px;
      }
      .name {
        margin-left: 5px;
      }
    }
  }
`

function Popup({ trigger, render, name, attachment, targetAttachment, customOutsideListener }) {
  const TriggerElement = trigger
  const RenderElement = render
  const elementRef = useRef()
  const triggerRef = useRef()
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    const handleOutsideClick = e => {
      if (elementRef.current && !elementRef?.current?.contains(e.target) && !triggerRef?.current?.contains(e.target)) {
        if (customOutsideListener) {
          customOutsideListener(e.target, setOpen, name)
        } else {
          setOpen(false)
        }
      }
    }
    document.addEventListener('click', handleOutsideClick)
    return () => document.removeEventListener('click', handleOutsideClick)
  }, [])

  return (
    <div>
      <TetherComponent
        attachment={attachment || 'bottom right'}
        targetAttachment={targetAttachment || 'bottom left'}
        constraints={[
          {
            to: 'scrollParent',
            attachment: 'together',
          },
        ]}
        renderTarget={ref => (
          <span
            ref={ref}
            onClick={() => {
              setOpen(!isOpen)
            }}
          >
            <div ref={triggerRef} className="select-target-class">
              <TriggerElement open={open} setOpen={setOpen} />
            </div>
          </span>
        )}
        renderElement={ref =>
          isOpen && (
            <div ref={ref} className="select-render-class">
              <TetherStyle ref={elementRef}>
                <RenderElement open={open} name={name} setOpen={setOpen} />
              </TetherStyle>
            </div>
          )
        }
      />
    </div>
  )
}

export default Popup
