import { updateVersion } from '@action/app'
import Cookie from '@lib/cookie'
import { getLastVersionSelector } from '@service/selectors/app'
import { Button } from '@style/components/button'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 15px;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.primary};
  p {
    margin-right: 15px;
    margin-bottom: 0;
    margin-top: 0;
    color: ${props => props.theme.colors.white};
  }
`

export default function NewVersion() {
  const Dispatch = useDispatch()
  const [version, setVersion] = useState('NEW_LOAD')
  const lastVersion = useSelector(getLastVersionSelector)
  const [isVersionMatch, setIsversionMatch] = useState(true)

  useEffect(() => {
    const currentVersion = Cookie.get('--app-version')
    if (currentVersion != 'NEW_LOAD') {
      setVersion(currentVersion)
    }
  }, [lastVersion])

  useEffect(() => {
    if (version != 'NEW_LOAD' && lastVersion != 'NEW_LOAD') {
      setIsversionMatch(lastVersion == version)
    }
  }, [version, lastVersion])

  return (
    <>
      {!isVersionMatch && (
        <Container>
          <p>
            The new version <b>{lastVersion}</b> has been released, click the update button to update the version
          </p>
          <Button btnColor="primary" btnBackground="white" sm onClick={() => Dispatch(updateVersion())}>
            Update
          </Button>
        </Container>
      )}
    </>
  )
}
