import Form, { FormGroup, FormInput, FormLabel, FormSelect } from '@util/form'
import { Button } from '@style/components/button'
import { Card, CardBody } from '@style/components/card'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { getSiteList } from '@action/site'
import { getActiveSite, getAllSiteSelector } from '@service/selectors/site'
import Image from 'next/image'
import Link from 'next/link'
import { getRoute } from '@hook/router'
import { useCallback } from 'react'
import Router, { useRouter } from 'next/router'

const Wrapper = styled.div`
  ${Card} {
    width: 500px;
    max-height: calc(100vh - 100px);
  }
`

const Footer = styled.div`
  display: flex;
  margin-top: 30px;
  ${Button} {
    width: 100%;
    justify-content: center;
  }
`
const Title = styled.h2`
  font-size: 28px;
  margin-top: 0;
  color: ${props => props.theme.colors.dark};
`

const WebsiteList = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 15px;
  cursor: pointer;

  ${props =>
    props.active &&
    `
  
    background-color: ${props.theme.colors.grey8};
  `}
`
const WebsiteLogo = styled.div`
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    object-fit: contain;
  }
`
const WebsiteDetail = styled.div`
  flex: 0 0 calc(100% - 60px);
  max-width: calc(100% - 60px);
  padding: 0 15px;
`
const WebsiteName = styled.h4`
  color: ${props => props.theme.colors.dark};
  font-weight: 500;
  margin: 0;
`
const WebsiteUrl = styled.span`
  font-size: ${props => props.theme.sizes.sm};
  color: ${props => props.theme.colors.grey5};
`

const FormSearch = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid ${props => props.theme.colors.grey7};
  border-radius: 10px;
  overflow: hidden;
  padding-right: 5px;
  padding-left: 3px;
  input {
    flex: 0 0 calc(100% - 25px);
    max-width: calc(100% - 25px);
    padding: 8px 10px;
    color: ${props => props.theme.colors.grey5};
    outline: none;
    border: 0;
    font-size: ${props => props.theme.sizes.md};
  }
  i {
    font-size: 20px;
  }
`

const WebsiteWrapper = styled.div`
  overflow: auto;
  margin-top: 20px;
  max-height: 500px;
`

const SiteImage = ({ url }) => {
  const [image, setImage] = useState(url)
  return <Image src={image} height={50} width={50} alt="" onError={e => setImage('/assets/images/site.png')} />
}

export default function ChooseSite({ close }) {
  const Dispatch = useDispatch()
  const [search, setSearch] = useState('')
  const [sites] = useSelector(getAllSiteSelector)
  const { query, pathname } = useRouter()
  useEffect(() => {
    Dispatch(getSiteList(search))
  }, [search])

  const setActiveSite = useCallback(siteId => {
    let url = pathname.split('[trackingId]')[0]
    url = url.replace('[id]', siteId)
    for (const key in query) {
      url = url.replace(`[${key}]`, query[key])
    }
    window.location.href = url
    close()
  }, [])

  return (
    <Wrapper>
      <Card>
        <CardBody>
          <Title>Choose Site</Title>
          <button className="close" onClick={close}>
            <i className="bx bx-x"></i>
          </button>
          <FormSearch>
            <input
              type="text"
              className="search-form"
              onChange={e => setSearch(e.target.value)}
              value={search}
              placeholder="Search site ..."
            />
            <i className="bx bx-search"></i>
          </FormSearch>
          <WebsiteWrapper>
            {sites.map(site => (
              <WebsiteList key={site.id} active={site.id == query.id} onClick={() => setActiveSite(site.id)}>
                <WebsiteLogo>
                  {/* <Image src={site.logoPath} height={50} width={50} alt="" /> */}
                  <SiteImage url={site.logoPath}></SiteImage>
                </WebsiteLogo>
                <WebsiteDetail>
                  <WebsiteName>{site.name}</WebsiteName>
                  <WebsiteUrl>{site.url}</WebsiteUrl>
                </WebsiteDetail>
              </WebsiteList>
            ))}
          </WebsiteWrapper>
          <Footer>
            <Link href={getRoute('site.all', {}, { create: true })}>
              <Button btnColor="white" onClick={close}>
                <i className="bx bx-plus"></i>
                <span style={{ marginLeft: 10 }}>Add New</span>
              </Button>
            </Link>
          </Footer>
        </CardBody>
      </Card>
    </Wrapper>
  )
}
