const FormCheck = props => {
  const { label, name, id } = props
  return (
    <>
      <div className="flex items-center">
        <input
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
          {...props}
          id={`${id}-check-form`}
          name={name}
          type="checkbox"
        />
        <label label={label} htmlFor={`${id}-check-form`} className="ml-2 cursor-pointer">
          {label}
        </label>
      </div>
    </>
  )
}

export default FormCheck
