import { getRoute, matchRoute } from '@/hooks/router'
import { DashboardMenuItem } from '@style/layouts/dashboard'
import ChooseSite from '@component/utils/header/choose-site'
import Image from 'next/image'
import Link from 'next/link'
import Router, { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import { toggleMenu } from '@action/app'
import classNames from 'classnames'
import { useState } from 'react'
import { getAllSiteSelector } from '@selector/site'
import Breadcrumbs from 'nextjs-breadcrumbs'

const package_json = require('../../package.json')

export default function Sidebar() {
  const Dispatch = useDispatch()
  const [sites] = useSelector(getAllSiteSelector)
  const isMenuExpand = useSelector(state => state.app?.menu?.expand)
  const { pathname, query } = useRouter()
  const [openedSubmenu, setOpenedSubmenu] = useState('')
  const isSettingPage = pathname.includes('settings')
  const siteId = !!query?.id ? query?.id : sites[0]?.id
  const handleGoBack = () => {
    Router.push(getRoute('site.detail', { id: siteId }))
  }

  const appVersion = !isMenuExpand ? `Seotisfy - v${package_json.version}` : `v${package_json.version}`

  return (
    <div
      className={classNames(
        { 'flex flex-[0_0_70px]': isMenuExpand },
        `flex flex-col h-[100vh] w-[240px] justify-between bg-white border-[1px] relative`
      )}
    >
      <div className="mt-2">
        {/*{isSettingPage ? null : (*/}
        <div className={classNames({ 'px-[16px]': isMenuExpand }, 'flex h-[65px] px-[26px]')}>
          <Link href={getRoute('dashboard.home')}>
            <a className="flex justify-center w-full">
              <Image
                alt="logo"
                src={isMenuExpand ? '/assets/images/logo-mini.svg' : '/assets/images/logo.svg'}
                height="100%"
                width={isMenuExpand ? 100 : 150}
              />
            </a>
          </Link>
        </div>
        {/*)}*/}

        {/* REGULAR MENU */}
        {!isSettingPage && (
          <>
            <div className="flex justify-center mb-5">
              {/*{query?.id && (*/}
              <div className="w-full relative">
                <ChooseSite />
              </div>
              {/*)}*/}
            </div>
            {!isMenuExpand && (
              <div className="pl-3 mb-1 font-bold">
                <h3 className="text-[16px]">Overview</h3>
              </div>
            )}
            <ul className={classNames({ 'hover:bg-lightBlue': !isMenuExpand }, 'flex p-0 m-0 mb-1 flex-col')}>
              <DashboardMenuItem
                className={matchRoute('dashboard.home', true, !isMenuExpand ? 'active' : 'toggleActive')}
              >
                <Link href={getRoute('dashboard.home')}>
                  <a
                    className={classNames(
                      { 'py-0': isMenuExpand },
                      'no-underline flex min-h-[20px] py-[3px] items-center pl-4 w-full'
                    )}
                  >
                    <div
                      className={classNames(
                        { 'hover:bg-lightBlue p-2 rounded-[4px]': isMenuExpand },
                        'flex flex-[0_0_30px]'
                      )}
                    >
                      <i className="bx bx-home-alt-2" style={{ fontSize: 20 }}></i>
                    </div>
                    <div className={classNames({ hidden: isMenuExpand })} style={{ flex: '0 0 calc(100% - 70px)' }}>
                      <span>Websites</span>
                    </div>
                  </a>
                </Link>
              </DashboardMenuItem>
            </ul>
            <ul className={classNames({ 'hover:bg-lightBlue': !isMenuExpand }, 'flex p-0 m-0 mb-1 flex-col')}>
              <DashboardMenuItem className={matchRoute('site.detail', true, !isMenuExpand ? 'active' : 'toggleActive')}>
                <Link href={getRoute('site.detail', { id: siteId })}>
                  <a
                    href={getRoute('site.detail', { id: siteId })}
                    className={classNames(
                      { 'py-0': isMenuExpand },
                      'no-underline flex min-h-[20px] py-[3px] items-center pl-4 w-full'
                    )}
                  >
                    <div
                      className={classNames(
                        { 'hover:bg-lightBlue p-2 rounded-[4px]': isMenuExpand },
                        'flex flex-[0_0_30px]'
                      )}
                    >
                      <i className="bx bx-line-chart" style={{ fontSize: 20 }}></i>
                    </div>
                    <div className={classNames({ hidden: isMenuExpand })} style={{ flex: '0 0 calc(100% - 70px)' }}>
                      <span>Performance</span>
                    </div>
                  </a>
                </Link>
              </DashboardMenuItem>
            </ul>
            {!isMenuExpand && (
              <div className="pl-3 mt-3 mb-1 font-bold">
                <h3 className="text-[16px]">Keyword Monitoring</h3>
              </div>
            )}
            <ul
              className={classNames(
                { 'hover:bg-transparent': isMenuExpand },
                'flex p-0 m-0 mb-1 flex-col hover:bg-lightBlue'
              )}
            >
              <DashboardMenuItem
                className={matchRoute('site.tracking.index', true, !isMenuExpand ? 'active' : 'toggleActive')}
              >
                <Link href={getRoute('site.tracking.index', { id: siteId })}>
                  <a
                    href={getRoute('site.tracking.index', { id: siteId })}
                    className={classNames(
                      { 'py-0': isMenuExpand },
                      'no-underline flex min-h-[20px] py-[3px] items-center pl-4 w-full'
                    )}
                  >
                    <div
                      className={classNames(
                        { 'hover:bg-lightBlue p-2 rounded-[4px]': isMenuExpand },
                        'flex flex-[0_0_30px]'
                      )}
                    >
                      <i className="bx bx-list-ul" style={{ fontSize: 20 }}></i>
                    </div>
                    <div className={classNames({ hidden: isMenuExpand })} style={{ flex: '0 0 calc(100% - 70px)' }}>
                      <span>Overview</span>
                    </div>
                  </a>
                </Link>
              </DashboardMenuItem>
            </ul>
            <ul className={classNames({ 'hover:bg-lightBlue': !isMenuExpand }, 'flex p-0 m-0 mb-1 flex-col')}>
              <DashboardMenuItem
                className={matchRoute('site.tracking.tags', true, !isMenuExpand ? 'active' : 'toggleActive')}
              >
                <Link href={getRoute('site.tracking.tags', { id: siteId })}>
                  <a
                    href={getRoute('site.tracking.tags', { id: siteId })}
                    className={classNames(
                      { 'py-0': isMenuExpand },
                      'no-underline flex min-h-[20px] py-[3px] items-center pl-4 w-full'
                    )}
                  >
                    <div
                      className={classNames(
                        { 'hover:bg-lightBlue p-2 rounded-[4px]': isMenuExpand },
                        'flex flex-[0_0_30px]'
                      )}
                    >
                      <i className="bx bx-purchase-tag" style={{ fontSize: 20 }}></i>
                    </div>
                    <div className={classNames({ hidden: isMenuExpand })} style={{ flex: '0 0 calc(100% - 70px)' }}>
                      <span>Tags</span>
                    </div>
                  </a>
                </Link>
              </DashboardMenuItem>
            </ul>
            {!isMenuExpand && (
              <div className="pl-3 mt-3 mb-1 font-bold">
                <h3 className="text-[16px]">Testing</h3>
              </div>
            )}
            <ul className={classNames({ 'hover:bg-lightBlue': !isMenuExpand }, 'flex p-0 m-0 mb-1 flex-col')}>
              <DashboardMenuItem
                className={matchRoute('site.seo.test', true, !isMenuExpand ? 'active' : 'toggleActive')}
              >
                <Link href={getRoute('site.seo.test', { id: siteId })}>
                  <a
                    href={getRoute('site.seo.test', { id: siteId })}
                    className={classNames(
                      { 'py-0': isMenuExpand },
                      'no-underline flex min-h-[20px] py-[3px] items-center pl-4 w-full'
                    )}
                  >
                    <div
                      className={classNames(
                        { 'hover:bg-lightBlue p-2 rounded-[4px]': isMenuExpand },
                        'flex flex-[0_0_30px]'
                      )}
                    >
                      <i className="bx bx-test-tube" style={{ fontSize: 20 }}></i>
                    </div>
                    <div className={classNames({ hidden: isMenuExpand })} style={{ flex: '0 0 calc(100% - 70px)' }}>
                      <span>Overview</span>
                    </div>
                  </a>
                </Link>
              </DashboardMenuItem>
            </ul>
            <ul className={classNames({ 'hover:bg-lightBlue': !isMenuExpand }, 'flex p-0 m-0 mb-1 flex-col')}>
              <DashboardMenuItem
                className={matchRoute('site.seo.test.create', true, !isMenuExpand ? 'active' : 'toggleActive')}
              >
                <Link href={getRoute('site.seo.test.create', { id: siteId })}>
                  <a
                    href={getRoute('site.seo.test.create', { id: siteId })}
                    className={classNames(
                      { 'py-0': isMenuExpand },
                      'no-underline flex min-h-[20px] py-[3px] items-center pl-4 w-full'
                    )}
                  >
                    <div
                      className={classNames(
                        { 'hover:bg-lightBlue p-2 rounded-[4px]': isMenuExpand },
                        'flex flex-[0_0_30px]'
                      )}
                    >
                      <i className="bx bx-book-add" style={{ fontSize: 20 }}></i>
                    </div>
                    <div className={classNames({ hidden: isMenuExpand })} style={{ flex: '0 0 calc(100% - 70px)' }}>
                      <span>Create Test</span>
                    </div>
                  </a>
                </Link>
              </DashboardMenuItem>
            </ul>
            {!isMenuExpand && (
              <div className="pl-3 mt-3 mb-1 font-bold">
                <h3 className="text-[16px]">Reports</h3>
              </div>
            )}
            <ul className={classNames({ 'hover:bg-lightBlue': !isMenuExpand }, 'flex p-0 m-0 mb-1 flex-col')}>
              <DashboardMenuItem
                className={matchRoute('site.reports.tracking', true, !isMenuExpand ? 'active' : 'toggleActive')}
              >
                <Link href={getRoute('site.reports.tracking', { id: siteId })}>
                  <a
                    className={classNames(
                      { 'py-0': isMenuExpand },
                      'no-underline flex min-h-[20px] py-[3px] items-center pl-4 w-full'
                    )}
                  >
                    <div
                      className={classNames(
                        { 'hover:bg-lightBlue p-2 rounded-[4px]': isMenuExpand },
                        'flex flex-[0_0_30px]'
                      )}
                    >
                      <i className="bx bx-link" style={{ fontSize: 20 }}></i>
                    </div>
                    <div className={classNames({ hidden: isMenuExpand })} style={{ flex: '0 0 calc(100% - 70px)' }}>
                      <span>Page tracking</span>
                    </div>
                  </a>
                </Link>
              </DashboardMenuItem>
            </ul>
            <ul className={classNames({ 'hover:bg-lightBlue': !isMenuExpand }, 'flex p-0 m-0 mb-1 flex-col')}>
              <DashboardMenuItem
                className={matchRoute('site.reports', true, !isMenuExpand ? 'active' : 'toggleActive')}
              >
                <Link href={getRoute('site.reports', { id: siteId })}>
                  <a
                    href={getRoute('site.reports', { id: siteId })}
                    className={classNames(
                      { 'py-0': isMenuExpand },
                      'no-underline flex min-h-[20px] py-[3px] items-center pl-4 w-full'
                    )}
                  >
                    <div
                      className={classNames(
                        { 'hover:bg-lightBlue p-2 rounded-[4px]': isMenuExpand },
                        'flex flex-[0_0_30px]'
                      )}
                    >
                      <i className="bx bxs-key" style={{ fontSize: 20 }}></i>
                    </div>
                    <div className={classNames({ hidden: isMenuExpand })} style={{ flex: '0 0 calc(100% - 70px)' }}>
                      <span>Content keyword missing</span>
                    </div>
                  </a>
                </Link>
              </DashboardMenuItem>
            </ul>
            <ul
              className={classNames(
                { 'hover:bg-white': isMenuExpand },
                'flex p-0 m-0 mb-1 flex-col hover:bg-lightBlue '
              )}
            >
              <DashboardMenuItem
                className={matchRoute('site.mentioned-keyword.meta', true, !isMenuExpand ? 'active' : 'toggleActive')}
              >
                <Link href={getRoute('site.mentioned-keyword.meta', { id: siteId })}>
                  <a
                    href={getRoute('site.mentioned-keyword.meta', { id: siteId })}
                    className={classNames(
                      { 'py-0': isMenuExpand },
                      'no-underline flex min-h-[20px] py-[3px] items-center pl-4 w-full'
                    )}
                  >
                    <div
                      className={classNames(
                        { 'hover:bg-lightBlue p-2 rounded-[4px]': isMenuExpand },
                        'flex flex-[0_0_30px]'
                      )}
                    >
                      <i className="bx bxs-key" style={{ fontSize: 20 }}></i>
                    </div>
                    <div className={classNames({ hidden: isMenuExpand })} style={{ flex: '0 0 calc(100% - 70px)' }}>
                      <span>Meta keywords missing</span>
                    </div>
                  </a>
                </Link>
              </DashboardMenuItem>
            </ul>
            <ul className={classNames({ 'hover:bg-lightBlue': !isMenuExpand }, 'flex p-0 m-0 mb-1 flex-col')}>
              <DashboardMenuItem
                className={matchRoute('site.changelog', true, !isMenuExpand ? 'active' : 'toggleActive')}
              >
                <Link href={getRoute('site.changelog', { id: siteId })}>
                  <a
                    href={getRoute('site.changelog', { id: siteId })}
                    className={classNames(
                      { 'py-0': isMenuExpand },
                      'no-underline flex min-h-[20px] py-[3px] items-center pl-4 w-full'
                    )}
                  >
                    <div
                      className={classNames(
                        { 'hover:bg-lightBlue p-2 rounded-[4px]': isMenuExpand },
                        'flex flex-[0_0_30px]'
                      )}
                    >
                      <i className="bx bx-spreadsheet" style={{ fontSize: 20 }}></i>
                    </div>
                    <div className={classNames({ hidden: isMenuExpand })} style={{ flex: '0 0 calc(100% - 70px)' }}>
                      <span>Changelog</span>
                    </div>
                  </a>
                </Link>
              </DashboardMenuItem>
            </ul>
            <ul className={classNames({ 'hover:bg-lightBlue': !isMenuExpand }, 'flex p-0 m-0 mb-1 flex-col')}>
              <DashboardMenuItem
                className={matchRoute('site.sitemap', true, !isMenuExpand ? 'active' : 'toggleActive')}
              >
                <Link href={getRoute('site.sitemap', { id: siteId })}>
                  <a
                    href={getRoute('site.sitemap', { id: siteId })}
                    className={classNames(
                      { 'py-0': isMenuExpand },
                      'no-underline flex min-h-[20px] py-[3px] items-center pl-4 w-full'
                    )}
                  >
                    <div
                      className={classNames(
                        { 'hover:bg-lightBlue p-2 rounded-[4px]': isMenuExpand },
                        'flex flex-[0_0_30px]'
                      )}
                    >
                      <i className="bx bx-sitemap" style={{ fontSize: 20 }}></i>
                    </div>
                    <div className={classNames({ hidden: isMenuExpand })} style={{ flex: '0 0 calc(100% - 70px)' }}>
                      <span>Sitemap monitor</span>
                    </div>
                  </a>
                </Link>
              </DashboardMenuItem>
            </ul>
            <ul className={classNames({ 'hover:bg-lightBlue': !isMenuExpand }, 'flex p-0 m-0 mb-1 flex-col')}>
              <DashboardMenuItem
                className={matchRoute('site.report.pdf', true, !isMenuExpand ? 'active' : 'toggleActive')}
              >
                <Link href={getRoute('site.report.pdf', { id: siteId })}>
                  <a
                    className={classNames(
                      { 'py-0': isMenuExpand },
                      'no-underline flex min-h-[20px] py-[3px] items-center pl-4 w-full'
                    )}
                  >
                    <div
                      className={classNames(
                        { 'hover:bg-lightBlue p-2 rounded-[4px]': isMenuExpand },
                        'flex flex-[0_0_30px]'
                      )}
                    >
                      <i className="bx bxs-file-pdf" style={{ fontSize: 20 }}></i>
                    </div>
                    <div className={classNames({ hidden: isMenuExpand })} style={{ flex: '0 0 calc(100% - 70px)' }}>
                      <span>Download</span>
                    </div>
                  </a>
                </Link>
              </DashboardMenuItem>
            </ul>
          </>
        )}

        {isSettingPage && (
          <>
            {/* SETTINGS MENU*/}
            <div onClick={() => handleGoBack()} className="mt-3">
              <div className={`${!isMenuExpand ? 'ml-6' : 'mb-[40px] ml-0 justify-center'} flex mb-1 cursor-pointer`}>
                <i className="bx bx-arrow-back bg-gray-200 rounded-full p-2"></i>
              </div>
            </div>
            {!isMenuExpand && (
              <div className="pl-3 mt-5 mb-1 font-bold">
                <h3>Personal settings</h3>
              </div>
            )}
            <ul className={classNames({ 'hover:bg-lightBlue': !isMenuExpand }, 'flex p-0 m-0 mb-1 flex-col')}>
              <DashboardMenuItem
                className={matchRoute('settings.profile', true, !isMenuExpand ? 'active' : 'toggleActive')}
              >
                <Link href={getRoute('settings.profile')}>
                  <a
                    href={getRoute('settings.profile')}
                    className={classNames(
                      { 'py-0': isMenuExpand },
                      'no-underline flex min-h-[20px] py-[3px] items-center pl-4 w-full'
                    )}
                  >
                    <div
                      className={classNames(
                        { 'hover:bg-lightBlue p-2 rounded-[4px]': isMenuExpand },
                        'flex flex-[0_0_30px]'
                      )}
                    >
                      <i className="bx bx-user" style={{ fontSize: 20 }}></i>
                    </div>
                    <div
                      className={classNames({ hidden: isMenuExpand }, 'ml-2')}
                      style={{ flex: '0 0 calc(100% - 70px)' }}
                    >
                      <span>Profile</span>
                    </div>
                  </a>
                </Link>
              </DashboardMenuItem>
            </ul>
            <ul className={classNames({ 'hover:bg-lightBlue': !isMenuExpand }, 'flex p-0 m-0 mb-1 flex-col')}>
              <DashboardMenuItem
                className={matchRoute('settings.billing', true, !isMenuExpand ? 'active' : 'toggleActive')}
              >
                <Link href={getRoute('settings.billing')}>
                  <a
                    href={getRoute('settings.billing')}
                    className={classNames(
                      { 'py-0': isMenuExpand },
                      'no-underline flex min-h-[20px] py-[3px] items-center pl-4 w-full'
                    )}
                  >
                    <div
                      className={classNames(
                        { 'hover:bg-lightBlue p-2 rounded-[4px]': isMenuExpand },
                        'flex flex-[0_0_30px]'
                      )}
                    >
                      <i className="bx bx-dollar-circle" style={{ fontSize: 20 }}></i>
                    </div>
                    <div
                      className={classNames({ hidden: isMenuExpand }, 'ml-2')}
                      style={{ flex: '0 0 calc(100% - 70px)' }}
                    >
                      <span>Billing</span>
                    </div>
                  </a>
                </Link>
              </DashboardMenuItem>
            </ul>
            {!isMenuExpand && (
              <div className="pl-3 mt-3 mb-1 font-bold">
                <h3>Application</h3>
              </div>
            )}
            <ul className={classNames({ 'hover:bg-lightBlue': !isMenuExpand }, 'flex p-0 m-0 mb-1 flex-col')}>
              <DashboardMenuItem
                className={matchRoute('settings.credential', true, !isMenuExpand ? 'active' : 'toggleActive')}
              >
                <Link href={getRoute('settings.credential')}>
                  <a
                    href={getRoute('settings.credential')}
                    className={classNames(
                      { 'py-0': isMenuExpand },
                      'no-underline flex min-h-[20px] py-[3px] items-center pl-4 w-full'
                    )}
                  >
                    <div
                      className={classNames(
                        { 'hover:bg-lightBlue p-2 rounded-[4px]': isMenuExpand },
                        'flex flex-[0_0_30px]'
                      )}
                    >
                      <i className="bx bx-key" style={{ fontSize: 20 }}></i>
                    </div>
                    <div
                      className={classNames({ hidden: isMenuExpand }, 'ml-2')}
                      style={{ flex: '0 0 calc(100% - 70px)' }}
                    >
                      <span>Credentials</span>
                    </div>
                  </a>
                </Link>
              </DashboardMenuItem>
            </ul>
          </>
        )}
      </div>

      <div className="mb-4 w-full">
        <ul className="flex p-0 mb-1 flex-col hover:bg-lightBlue">
          <DashboardMenuItem>
            <a
              href="https://help.seotisfy.com/"
              target="_blank"
              className={classNames(
                { 'py-3 text-xl': isMenuExpand },
                'flex items-center justify-center no-underline min-h-[34px] w-full'
              )}
              rel="noreferrer"
            >
              <div className="flex">
                <i className="bx bx-help-circle" style={{ fontSize: 20 }}></i>
              </div>
              <div className={classNames({ hidden: isMenuExpand }, 'ml-2')} style={{ flex: '0 0 calc(100% - 70px)' }}>
                <span>Help Center</span>
              </div>
            </a>
          </DashboardMenuItem>
        </ul>
        {isSettingPage ? null : (
          <ul className="flex p-0 mx-0 mb-1 flex-col hover:bg-lightBlue">
            <DashboardMenuItem className={matchRoute('settings.profile', true) || matchRoute('settings.profile', true)}>
              <Link href={getRoute('settings.profile')}>
                <a
                  href={getRoute('settings.profile')}
                  className={classNames(
                    { 'py-3 text-xl': isMenuExpand },
                    'items-center justify-center no-underline flex min-h-[34px] w-full'
                  )}
                >
                  <div className="flex">
                    <i className="bx bx-cog flex text-[20px]" style={{ fontSize: 20 }}></i>
                  </div>
                  <div
                    className={classNames({ hidden: isMenuExpand }, 'ml-2')}
                    style={{ flex: '0 0 calc(100% - 70px)' }}
                  >
                    <span>Settings</span>
                  </div>
                </a>
              </Link>
            </DashboardMenuItem>
          </ul>
        )}
        <div
          className="flex items-center justify-center no-underline w-full mb-2 text-[#8A9099] cursor-pointer min-h-[34px] hover:bg-lightBlue"
          onClick={() => Dispatch(toggleMenu())}
        >
          {isMenuExpand ? (
            <div className="flex">
              <i className="bx bx-chevron-right flex" style={{ fontSize: 20 }}></i>
            </div>
          ) : (
            <>
              <div className="flex">
                <i className="bx bx-chevron-left text-[28px] flex" style={{ fontSize: 24 }} />
              </div>
              <div className={classNames({ hidden: isMenuExpand }, 'ml-2')} style={{ flex: '0 0 calc(100% - 70px)' }}>
                <span className="text-[12px] text-grey5 cursor-pointer">Collapse</span>
              </div>
            </>
          )}
        </div>
        <p className="w-full text-xs text-gray-300 text-center">{appVersion}</p>
      </div>
    </div>
  )
}
