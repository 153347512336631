import { Button } from '@style/components/button'

export default function LogButton({ onClick }) {
  return (
    <button
      onClick={onClick}
      className="bg-primary bottom-10 z-[1000] right-10 fixed rounded-[8px] text-white font-bold px-6 py-2 text-sm hover:bg-primary_darken"
    >
      +<span className="opacity-0">_</span>Log
    </button>
  )
}
