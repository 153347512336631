import {
  InpputFileWrap,
  InputImageWrapper,
  InputFileName,
  InputFileLabel,
  InputFileDesc,
} from '@/styles/components/form'

/**
 * Input file
 * id, iconName, label, placeholder, onChange, accept, image, maxSize, children, customContent
 * @param {String} id
 * @param {String} iconName
 * @param {String} label
 * @param {String} placeholder
 * @param {Function} onChange
 * @param {String} accept
 * @param {String} image
 * @param {String} maxSize
 * @param {String} children
 * @param {String} customContent

 * 
 */
const formFileWrapper = props => {
  const { id, iconName, label, placeholder, onChange, accept, image, maxSize, children, customContent } = props
  const handleFileChange = e => {
    if (e?.target?.files) {
      if (e?.target?.files[0]?.size <= maxSize * 1024 * 1024) {
        return onChange(e)
      } else {
        alert(`File is to large max : ${maxSize} mb`)
      }
    }
  }
  return (
    <InpputFileWrap customContent={customContent} {...props}>
      {customContent ? (
        children
      ) : (
        <>
          <InputImageWrapper width="40px">
            {image ? <img src={image} alt="" /> : <i className={`bx bx-${iconName}`}></i>}
          </InputImageWrapper>
          <InputFileName>
            <InputFileLabel>{label}</InputFileLabel>
            <InputFileDesc>{placeholder}</InputFileDesc>
          </InputFileName>
        </>
      )}
      <input type="file" id={id} accept={accept || '*/*'} onChange={handleFileChange} />
    </InpputFileWrap>
  )
}

export default formFileWrapper
