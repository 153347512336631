import { createSelector } from 'reselect'

export const getProfileSubscription = createSelector(
  state => state?.profile?.subscription,
  subscription => subscription
)

export const getSubscriptionStatus = createSelector(
  state => state?.profile?.subscriptionStatus,
  subscription => subscription
)
