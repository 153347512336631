import ReactSelect from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { FormError } from '@style/components/form'
import _ from 'lodash'
import getFormValue from '@/hooks/form-getter'

export const ReactSelectStyles = ({ small }) => ({
  menu: (provided, state) => ({
    ...provided,
    width: '100%',
    color: state.selectProps.menuColor,
    padding: 10,
  }),

  control: (_, { selectProps: { width } }) => ({
    width: 'calc(100% - 30px)',
    minHeight: small ? '35px' : '50px',
    display: 'flex',
    alignItems: 'center',
  }),
  container: (provided, state) => ({
    ...provided,
    // background: '#F2F4F6',
    border: '1px solid #D8D8DD;',
    borderRadius: small ? '7px' : '10px',
    display: 'flex',
    alignItems: 'center',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    position: 'absolute',
    right: '0',
  }),
  option: (provided, state) => ({
    ...provided,
    minWidth: '240px !important',
    width: '100%',
  }),
})

const Select = props => {
  const { form, options, name, withError, multi, creatable, sm, onChange } = props

  const error = form?.errors[name]
  const touched = form?.touched[name]

  const rSelectOptions = multi
    ? _.uniqBy([...options, ...getFormValue(form, name)], 'value').map(a => ({ label: a.label, value: a.value }))
    : options

  const values = multi
    ? rSelectOptions?.filter(itm => (getFormValue(form, name) || [])?.find(val => itm?.value == val.value))
    : options?.find(itm => itm?.value == (getFormValue(form, name)?.value || getFormValue(form, name)))

  const RSelect = creatable ? CreatableSelect : ReactSelect

  return (
    <>
      <RSelect
        {...props}
        value={values}
        options={rSelectOptions}
        onFocus={() => form?.setFieldTouched(name, true)}
        onBlur={form?.handleBlur}
        onChange={val => {
          if (onChange) {
            onChange(val)
          }
          form.setValues({
            ...form.values,
            [name]: multi ? val : val.value,
          })
        }}
        styles={ReactSelectStyles({ small: sm })}
        allowCreateWhileLoading={true}
        createOptionPosition='last'
        isMulti={multi}
        isDisabled={props?.disabled}
      ></RSelect>
      {error && withError && touched && (
        <FormError className='error-input'>{_.upperFirst(error.replaceAll('_', ' ')?.replace('md ', ''))}</FormError>
      )}
    </>
  )
}

export default Select
