/**
 * Get formin value by string name
 * @param {*} forms
 * @param {*} name
 */

export default function getFormValue(forms, name) {
  let values = forms?.values
  const splitedName = name.split('.')
  for (const na of splitedName) {
    values = values?.[na]
  }
  return values || ''
}
