import { getRoute, useRouter } from '@hook/router'
import Cookie from '@lib/cookie'
import { getProfile } from '@api/profile'

/**
 * get user from api doctor
 * @param {*} ctx
 * @param {*} acceptRole
 * @param {*} aceptStatus
 */

/**
 * Handling user with role only
 * @param {*} ctx
 */

async function checkAuth(ctx) {
  try {
    return await getProfile(ctx)
  } catch (e) {
    return false
  }
}

export async function handle(ctx) {
  const Route = useRouter(ctx)
  const cookie = new Cookie(ctx)
  // cookies
  const tryToDeletCookie = () => {
    cookie.remove('--app-auth-token')
    cookie.remove('--app-user-id')
  }
  const authToken = cookie.get('--app-auth-token')
  if (authToken) {
    try {
      const authUser = await checkAuth(ctx)
      const isAuth = authUser?.ok
      cookie.set('--app-user-id', authUser?.data?.data?.id)

      if (!isAuth) {
        tryToDeletCookie()
        Route.push(getRoute('auth.login'))
      }
      // do dashboard
    } catch (_) {
      tryToDeletCookie()
      Route.push(getRoute('auth.login'))
    }
  } else {
    tryToDeletCookie()
    Route.push(getRoute('auth.login'))
  }
}
