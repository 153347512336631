import styled from 'styled-components'

export const Dashboard = styled.div`
  position: relative;
  display: flex;
`

export const DashboardHeader = styled.div`
  display: flex;
  height: 65px;
  padding: 7px 26px;
`

export const DashboardLogo = styled.a`
  display: flex;
  align-items: center;

  span {
    font-size: 34px;
    margin-right: auto;
    font-weight: bold;
    color: ${props => props.theme.colors.dark};
  }

  img {
    object-fit: contain;
  }
`

export const DashboardMenu = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  flex-direction: column;
`

export const DashboadMenuContent = styled.a`
  text-decoration: none;
  display: flex;
  min-height: 20px;
  padding: 5px 5px 5px 25px;
  align-items: center;
  width: 100%;
`
export const DashboadMenuSubContent = styled.a`
  text-decoration: none;
  display: flex;
  margin-left: 30px;
  min-height: 55px;
  padding: 18px 5px 18px 25px;
  align-items: center;
  width: 100%;
`

export const DashboardMenuItem = styled.li`
  display: flex;
  cursor: pointer;
  position: relative;

  span {
    font-size: 12px;
    color: ${props => props.theme.colors.grey5};
  }

  i {
    color: ${props => props.theme.colors.grey5};
  }

  &.toggleActive {
    span {
      color: ${props => props.theme.colors.dark};
      font-weight: 700;
    }

    div {
      background-color: ${props => props.theme.colors.lightBlue};
    }

    i {
      color: ${props => props.theme.colors.primary};
    }

    * {
      color: ${props => props.theme.colors.dark};
    }
  }

  &.active {
    background-color: ${props => props.theme.colors.lightBlue};

    span {
      color: ${props => props.theme.colors.dark};
      font-weight: 700;
    }

    i {
      color: ${props => props.theme.colors.primary};
    }

    * {
      color: ${props => props.theme.colors.dark};
    }

    &::before {
      right: 0;
      top: 0;
      height: 100%;
      content: '';
      width: 4px;
      background-color: ${props => props.theme.colors.primary};
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      position: absolute;
    }
  }
`

export const DashboardMenuSubItem = styled.li`
  font-size: 14px;
  display: flex;
  width: 100%;
  cursor: pointer;
  position: relative;

  span {
    font-size: 14px;
    color: ${props => props.theme.colors.dark};
  }

  i {
    font-size: 18px;
    color: ${props => props.theme.colors.grey9};
  }

  &.active {
    background-color: ${props => props.theme.colors.grey8};

    i {
      font-size: 18px;
    }

    * {
      color: ${props => props.theme.colors.dark};
    }
  }
`

export const DashboardMenuIcon = styled.div`
  flex: 0 0 30px;
`
export const DashboardMenuChevronIcon = styled.div`
  display: flex;
`
export const DashboardMenuName = styled.div`
  flex: 0 0 calc(100% - 70px);
`

export const DashboardMain = styled.div`
  display: flex;
  flex: 0 0 calc(100% - 240px);
  flex-direction: column;
  max-width: calc(100% - 80px);
  overflow: hidden;

  &.expand {
    flex: 0 0 calc(100% - 80px);
    max-width: calc(100% - 80px);
  }

  &.fullSize {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
`

export const DashboardSide = styled.div`
  flex: 0 0 270px;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.white};
  height: 100vh;
  max-width: 270px;

  &.collapseDashboard {
    flex: 0 0 80px;
    max-width: 80px;

    ${DashboardMenuName} {
      display: none;
    }

    ${DashboadMenuContent} {
      padding: 25px;
    }

    i {
      font-size: 22px;
    }

    ${DashboardHeader} {
      padding: 7px 10px;
    }
  }
`

export const DashboardWrap = styled.div`
  height: calc(100vh - 110px);
  padding: 20px 30px 50px 30px;
  width: 100%;
  overflow: auto;
`

export const DashboardHead = styled.div`
  background-color: ${props => props.theme.colors.white};
  height: 65px;
  border-bottom: 1px solid ${props => props.theme.colors.grey7};
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 5px 30px;
  margin-bottom: 46px;
`

export const DashboardNavMode = styled.button`
  background-color: transparent;
  font-size: 28px;
  align-items: center;
  width: 100%;
  color: ${props => props.theme.colors.dark};
  padding: 0;
`

export const DashboardRightMenu = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`

export const DropDownUser = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 10000px;

  :hover {
    background-color: #f1f1f1;
  }
`

export const RightMenuAction = styled.button`
  background-color: transparent;
  display: flex;
  position: relative;
  margin-right: 8px;

  &:not(:first-child) {
    border-right: 1px solid ${props => props.theme.colors.grey7};
    margin-right: 15px;
    padding-right: 10px;
  }

  i {
    color: ${props => props.theme.colors.dark};
    font-size: 24px;
  }

  .doted {
    height: 5px;
    width: 5px;
    position: absolute;
    top: 5px;
    display: block;
    right: 14px;
    border-radius: 100%;
    background-color: ${props => props.theme.colors.red};
  }
`

export const DashboardTitle = styled.h2`
  white-space: nowrap;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 28px;
  margin-top: 0;
  color: ${props => props.theme.colors.dark};
`
export const DashboardSubtitle = styled.h3`
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 14px;
  margin-top: 0;
  color: ${props => props.theme.colors.dark};
`

export const DashboardTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const DashboardUrlField = styled.div`
  border-radius: 12px;
  border: 1px solid #e8e9eb;
  margin: 0 20px;
  padding: 7px 24px;
  width: 100%;
`
