export const getDaysBetweenDateToNow = (dateTimestamp) => {
  const daysLeft =
    new Date(dateTimestamp * 1000).getTime() - new Date(Date.now()).getTime()
  return Math.round(daysLeft / (24 * 3600 * 1000))
}

export const formatDateToLocaleDateString = (dateTimestamp) => {
  const newDate = new Date(dateTimestamp * 1000)
  return newDate.toLocaleDateString('en-US', {
    // TODO - use I18n var for fr-FR, en-GB
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  })
}
