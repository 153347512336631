import styled from 'styled-components'
import { FormError } from '@style/components/form'
import _ from 'lodash'
import { useEffect } from 'react'
import getFormValue from '@hook/form-getter'


export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid ${props => props.theme.colors.grey7};
  border-radius: 10px;
  padding: 0 10px;
  overflow: hidden;
  margin-bottom: 10px;
  input {
    margin-left: 10px;
    width: 100%;
    min-height: 40px;
    border: 0;
    color: ${props => props.theme.colors.dark};
    outline: none;
  }
  select {
    background-color: ${props => props.theme.colors.white};
    width: 100%;
    min-height: 40px;
    border: 0;
    color: ${props => props.theme.colors.dark};
    outline: none;
  }
  i {
    color: ${props => props.theme.colors.grey9};
    font-size: 22px;
  }
`

const FormInputStyle = styled.input`
  padding: 10px 20px;
  border: 1px solid ${props => props.theme.colors.grey40};
  width: 100%;
  display: block;
  border-radius: 10px;
  font-size: ${props => props.theme.sizes.md};
  :placeholder {
    background-color: ${props => props.theme.colors.grey20};
  }
  :disabled {
    ${props => props.theme.colors.grey30};
  }
  ${props =>
    props.beforeLabel &&
    `
      padding-left: 52px !important;
    `}
  ${props =>
    props.inputStyle === 'bottom-line' &&
    `
      border-top: 0px !important;
      border-left: 0px !important;
      padding-left: 0px !important;
      border-right: 0px !important;
    `}
  ${props =>
    props.sm &&
    `
    border-radius: 7px;
    padding: 9px 15px;
  `}
`

const BeforeLabelStyle = styled.span`
  position: absolute;
  left: 13px;
  top: 42px;
`

const Input = ({
  className,
  beforeLabel = null,
  name = 'input-name',
  type,
  form,
  disabled,
  withError = false,
  placeholder = '',
  value = null,
  errors = null,
  inputStyle = 'normal',
  sm = false,
  onChange = () => {},
}) => {
  const error = form?.errors[name]
  const touched = form.touched[name]
  const restErrors = errors?.[name]

  useEffect(() => {
    if (value?.length) {
      form?.setFieldValue(name, value)
    }
  }, [value, form, name])

  return (
    <>
      <BeforeLabelStyle>{beforeLabel}</BeforeLabelStyle>
      <FormInputStyle
        className={className}
        name={name}
        type={type}
        beforeLabel={beforeLabel}
        onChange={e => {
          form?.setFieldValue(name, e.currentTarget.value)
          onChange(e)
        }}
        sm={sm}
        inputStyle={inputStyle}
        disabled={disabled}
        onFocus={() => form?.setFieldTouched(name, true)}
        onBlur={form?.handleBlur}
        value={getFormValue(form, name)}
        placeholder={placeholder}
      />

      {((error && withError && touched) || restErrors) && (
        <FormError className="error-input">
          {restErrors || _.upperFirst(error.replaceAll('_', ' ')?.replace('md ', ''))}
        </FormError>
      )}
    </>
  )
}

export default Input
