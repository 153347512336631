import { FormRadioInputStyle, FormRadioStyle, FormRadioLabelStyle } from '@style/components/form'

const FormRadio = props => {
  const { label, name, id, form, value } = props

  return (
    <>
      <FormRadioInputStyle>
        <FormRadioStyle
          {...props}
          checked={(form?.values && form?.values[name]) === value}
          id={id}
          name={name}
          type="radio"
          value={value}
          onChange={() => {
            form.setFieldTouched(name)
            form.setValues({
              ...form.values,
              [name]: value,
            })
          }}
        />
        <FormRadioLabelStyle htmlFor={id}>{label} </FormRadioLabelStyle>
      </FormRadioInputStyle>
    </>
  )
}

export default FormRadio
