import { InputGroup } from '@style/components/form'
import { FormError } from '@style/components/form'

const FormInputGroup = props => {
  const error = props?.form?.errors[props.withError]
  const touched = props?.form?.touched[props.withError]
  return (
    <InputGroup {...props}>
      {props.children}
      {props.withError && touched && <FormError>{error} </FormError>}
    </InputGroup>
  )
}
export default FormInputGroup
