import { getRoute, matchRoute } from '@hook/router'
import { getAllSiteSelector } from '@service/selectors/site'
import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const BreadcrumbsItem = styled.li`
  display: none;
  cursor: pointer;
  position: relative;
  &.active {
    display: flex;
  }
`

const Breadcrumbs = () => {
  const [sites] = useSelector(getAllSiteSelector)
  const { pathname, query } = useRouter()
  const router = useRouter()
  const siteId = !!query?.id ? query?.id : sites[0]?.id

  return (
    <div className="absolute left-[270px] top-[74px] capitalize text-blue">
      <ul className="flex gap-1">
        <BreadcrumbsItem className={matchRoute('dashboard.home', true, 'active')}>
          <Link href={getRoute('dashboard.home', { id: siteId })}>
            <p>
              Overview <span className="mx-1">&gt;</span> <span className="text-grey9">Websites</span>
            </p>
          </Link>
        </BreadcrumbsItem>
        <BreadcrumbsItem className={matchRoute('site.detail', true, 'active')}>
          <Link href={getRoute('site.detail', { id: siteId })}>
            <p>
              Overview <span className="mx-1">&gt;</span> <span className="text-grey9">Performance</span>
            </p>
          </Link>
        </BreadcrumbsItem>
        <BreadcrumbsItem className={matchRoute('site.tracking.index', true, 'active')}>
          <Link href={getRoute('site.tracking.index', { id: siteId })}>
            <p>
              Keyword Monitoring <span className="mx-1">&gt;</span> <span className="text-grey9">Overview</span>
            </p>
          </Link>
        </BreadcrumbsItem>
        <BreadcrumbsItem className={matchRoute('site.tracking.tags', true, 'active')}>
          <Link href={getRoute('site.tracking.tags', { id: siteId })}>
            <p>
              Keyword Monitoring <span className="mx-1">&gt;</span> <span className="text-grey9">Tags</span>
            </p>
          </Link>
        </BreadcrumbsItem>
        <BreadcrumbsItem className={matchRoute('site.seo.test', true, 'active')}>
          <Link href={getRoute('site.seo.test', { id: siteId })}>
            <p>
              Testing <span className="mx-1">&gt;</span> <span className="text-grey9">Overview</span>
            </p>
          </Link>
        </BreadcrumbsItem>
        <BreadcrumbsItem className={matchRoute('site.seo.test.create', true, 'active')}>
          <Link href={getRoute('site.seo.test', { id: siteId })}>
            <p className="">
              Testing <span className="mx-1">&gt;</span>
            </p>
          </Link>

          <p className="text-grey9">Create</p>
        </BreadcrumbsItem>
        <BreadcrumbsItem className={matchRoute('site.seo.test.seo-title', true, 'active')}>
          <Link href={getRoute('site.seo.test', { id: siteId })}>
            <p>
              Testing<span className="mx-1">&gt;</span>
            </p>
          </Link>
          <Link href={getRoute('site.seo.test.create', { id: siteId })}>
            <p>
              Create<span className="mx-1">&gt;</span>
            </p>
          </Link>
          <p className="text-grey9">Title Test</p>
        </BreadcrumbsItem>
        <BreadcrumbsItem className={matchRoute('site.seo.test.meta-desc', true, 'active')}>
          <Link href={getRoute('site.seo.test', { id: siteId })}>
            <p>
              Testing<span className="mx-1">&gt;</span>
            </p>
          </Link>
          <Link href={getRoute('site.seo.test.create', { id: siteId })}>
            <p>
              Create<span className="mx-1">&gt;</span>
            </p>
          </Link>
          <p className="text-grey9">Meta Test</p>
        </BreadcrumbsItem>
        <BreadcrumbsItem className={matchRoute('site.seo.test.schema-implementation', true, 'active')}>
          <Link href={getRoute('site.seo.test', { id: siteId })}>
            <p>
              Testing<span className="mx-1">&gt;</span>
            </p>
          </Link>
          <Link href={getRoute('site.seo.test.create', { id: siteId })}>
            <p>
              Create<span className="mx-1">&gt;</span>
            </p>
          </Link>
          <p className="text-grey9">Schema Test</p>
        </BreadcrumbsItem>
        <BreadcrumbsItem className={matchRoute('site.seo.test.redirect-test', true, 'active')}>
          <Link href={getRoute('site.seo.test', { id: siteId })}>
            <p>
              Testing<span className="mx-1">&gt;</span>
            </p>
          </Link>
          <Link href={getRoute('site.seo.test.create', { id: siteId })}>
            <p>
              Create<span className="mx-1">&gt;</span>
            </p>
          </Link>
          <Link href={getRoute('site.seo.test.redirect-test', { id: siteId })}>Redirect Test</Link>
        </BreadcrumbsItem>
        <BreadcrumbsItem className={matchRoute('site.seo.test.backlink-test', true, 'active')}>
          <Link href={getRoute('site.seo.test', { id: siteId })}>
            <p>
              Testing<span className="mx-1">&gt;</span>
            </p>
          </Link>
          <Link href={getRoute('site.seo.test.create', { id: siteId })}>
            <p>
              Create<span className="mx-1">&gt;</span>
            </p>
          </Link>
          <p className="text-grey9">Backlink Test</p>
        </BreadcrumbsItem>
        <BreadcrumbsItem className={matchRoute('site.seo.test.content-change', true, 'active')}>
          <Link href={getRoute('site.seo.test', { id: siteId })}>
            <p>
              Testing<span className="mx-1">&gt;</span>
            </p>
          </Link>
          <Link href={getRoute('site.seo.test.create', { id: siteId })}>
            <p>
              Create<span className="mx-1">&gt;</span>
            </p>
          </Link>
          <p className="text-grey9">Content Change Test</p>
        </BreadcrumbsItem>
        <BreadcrumbsItem className={matchRoute('site.seo.test.advanced', true, 'active')}>
          <Link href={getRoute('site.seo.test', { id: siteId })}>
            <p>
              Testing<span className="mx-1">&gt;</span>
            </p>
          </Link>
          <Link href={getRoute('site.seo.test.create', { id: siteId })}>
            <p>
              Create<span className="mx-1">&gt;</span>
            </p>
          </Link>
          <p className="text-grey9">Advanced Test</p>
        </BreadcrumbsItem>
        <BreadcrumbsItem className={matchRoute('site.seo.test.upload.single', true, 'active')}>
          <Link href={getRoute('site.seo.test', { id: siteId })}>
            <p>
              Testing<span className="mx-1">&gt;</span>
            </p>
          </Link>
          <Link href={getRoute('site.seo.test.create', { id: siteId })}>
            <p>
              Create<span className="mx-1">&gt;</span>
            </p>
          </Link>
          <p className="text-grey9">Upload Single Test</p>
        </BreadcrumbsItem>
        <BreadcrumbsItem
          className={classNames({ active: pathname === '/site/[id]/seo-test/result/[testType]/[seoTestingId]' })}
        >
          <Link href={getRoute('site.seo.test', { id: siteId })}>
            <p>
              Testing<span className="mx-1">&gt;</span>
            </p>
          </Link>
          <Link href={getRoute('site.seo.test.create', { id: siteId })}>
            <p>
              Create<span className="mx-1">&gt;</span>
            </p>
          </Link>
          <p className="text-grey9">Test Result</p>
        </BreadcrumbsItem>
        <BreadcrumbsItem
          className={classNames({ active: pathname === '/site/[id]/seo-test/result/[testType]/[seoTestingId]/raw' })}
        >
          <Link href={getRoute('site.seo.test', { id: siteId })}>
            <p>
              Testing<span className="mx-1">&gt;</span>
            </p>
          </Link>
          <Link href={getRoute('site.seo.test.create', { id: siteId })}>
            <p>
              Create<span className="mx-1">&gt;</span>
            </p>
          </Link>
          <div onClick={() => router.back()}>
            <p>
              Test Result<span className="mx-1">&gt;</span>
            </p>
          </div>
          <p className="text-grey9">Raw</p>
        </BreadcrumbsItem>
        <BreadcrumbsItem
          className={classNames({ active: pathname === '/site/[id]/seo-test/result/[testType]/[seoTestingId]/edit' })}
        >
          <Link href={getRoute('site.seo.test', { id: siteId })}>
            <p>
              Testing<span className="mx-1">&gt;</span>
            </p>
          </Link>
          <Link href={getRoute('site.seo.test.create', { id: siteId })}>
            <p>
              Create<span className="mx-1">&gt;</span>
            </p>
          </Link>
          <div onClick={() => router.back()}>
            <p>
              Test Result<span className="mx-1">&gt;</span>
            </p>
          </div>
          <p className="text-grey9">Edit</p>
        </BreadcrumbsItem>
        <BreadcrumbsItem
          className={classNames({ active: pathname === '/site/[id]/seo-test/result/[testType]/[seoTestingId]/clone' })}
        >
          <Link href={getRoute('site.seo.test', { id: siteId })}>
            <p>
              Testing<span className="mx-1">&gt;</span>
            </p>
          </Link>
          <Link href={getRoute('site.seo.test.create', { id: siteId })}>
            <p>
              Create<span className="mx-1">&gt;</span>
            </p>
          </Link>
          <div onClick={() => router.back()}>
            <p>
              Test Result<span className="mx-1">&gt;</span>
            </p>
          </div>
          <p className="text-grey9">Copy</p>
        </BreadcrumbsItem>
        <BreadcrumbsItem className={matchRoute('site.reports.tracking', true, 'active')}>
          <Link href={getRoute('site.reports.tracking', { id: siteId })}>
            <p>
              Reports <span className="mx-1">&gt;</span> <span className="text-grey9">Page tracking</span>
            </p>
          </Link>
        </BreadcrumbsItem>
        <BreadcrumbsItem className={matchRoute('site.mentioned-keyword', true, 'active')}>
          <Link href={getRoute('site.mentioned-keyword', { id: siteId })}>
            <p>
              Reports <span className="mx-1">&gt;</span> <span className="text-grey9">Content keyword missing</span>
            </p>
          </Link>
        </BreadcrumbsItem>
        <BreadcrumbsItem className={matchRoute('site.mentioned-keyword.meta', true, 'active')}>
          <Link href={getRoute('site.mentioned-keyword.meta', { id: siteId })}>
            <p>
              Reports <span className="mx-1">&gt;</span> <span className="text-grey9">Meta keyword missing</span>
            </p>
          </Link>
        </BreadcrumbsItem>
        <BreadcrumbsItem className={matchRoute('site.changelog', true, 'active')}>
          <Link href={getRoute('site.changelog', { id: siteId })}>
            <p>
              Reports <span className="mx-1">&gt;</span> <span className="text-grey9">Changelog</span>
            </p>
          </Link>
        </BreadcrumbsItem>
        <BreadcrumbsItem className={matchRoute('site.sitemap', true, 'active')}>
          <Link href={getRoute('site.sitemap', { id: siteId })}>
            <p>
              Reports <span className="mx-1">&gt;</span> <span className="text-grey9">Sitemap Monitor</span>
            </p>
          </Link>
        </BreadcrumbsItem>
        <BreadcrumbsItem className={matchRoute('settings.profile', true, 'active')}>
          <Link href={getRoute('site.sitemap', { id: siteId })}>
            <p>
              Settings <span className="mx-1">&gt;</span> Personal Settings <span className="mx-1">&gt;</span>{' '}
              <span className="text-grey9">Profile</span>
            </p>
          </Link>
        </BreadcrumbsItem>
        <BreadcrumbsItem className={matchRoute('settings.billing', true, 'active')}>
          <Link href={getRoute('settings.billing', { id: siteId })}>
            <p>
              Settings <span className="mx-1">&gt;</span> Personal Settings <span className="mx-1">&gt;</span>{' '}
              <span className="text-grey9">Billing</span>
            </p>
          </Link>
        </BreadcrumbsItem>
        <BreadcrumbsItem className={matchRoute('settings.credential', true, 'active')}>
          <Link href={getRoute('settings.credential', { id: siteId })}>
            <p>
              Settings <span className="mx-1">&gt;</span> Personal Settings <span className="mx-1">&gt;</span>{' '}
              <span className="text-grey9">Credentials</span>
            </p>
          </Link>
        </BreadcrumbsItem>
      </ul>
    </div>
  )
}

export default Breadcrumbs
