import { getRoute } from '@/hooks/router'
import { DashboardHead, DashboardRightMenu, DropDownUser } from '@style/layouts/dashboard'
import Image from 'next/image'
import { useDispatch, useSelector } from 'react-redux'
import Popup from '@component/utils/popup'
import styled from 'styled-components'
import { useCallback } from 'react'
import { logout } from '@action/auth'
import Link from 'next/link'
import { getProfileSubscription } from '@selector/profile/subscription'
import { getDaysBetweenDateToNow } from '@/utils/date'
import { useMemo } from 'react'
import Breadcrumbs from './breadcrumbs'
// import Breadcrumbs from 'nextjs-breadcrumbs';


const DropdownUser = styled.ul`
  min-width: 200px;
  margin-top: 54px !important;
  padding: 4px 0px;
`

export default function Header() {
  const Dispatch = useDispatch()
  const profile = useSelector(state => state?.profile?.profile?.data || {})
  const subscription = useSelector(getProfileSubscription)
  const handleLogout = useCallback(close => {
    Dispatch(logout())
    close()
  }, [])
  const { trialDays } = useMemo(() => {
    return {
      trialDays:
        (subscription?.data &&
          subscription?.data['trial_end'] &&
          getDaysBetweenDateToNow(subscription?.data['trial_end'])) ||
        0,
    }
  }, [subscription])

  return (
    <DashboardHead>
        {/* <Breadcrumbs  omitIndexList={[0,1]}  inactiveItemClassName="after:content-['__>'] breadcrumb-last" containerClassName='absolute left-[270px] top-[74px] capitalize text-blue' listClassName="flex gap-1 " rootLabel='Dashboard'/> */}
        <Breadcrumbs/>
      
      <DashboardRightMenu>
        {/* <RightMenuAction>
            <i className="bx bx-search"></i>
          </RightMenuAction> */}
        {/* <RightMenuAction className="border-right">
            <i className="bx bx-bell"></i>
            <span className="doted"></span>
          </RightMenuAction> */}
        <Popup
          attachment="top right"
          targetAttachment="top right"
          trigger={() => (
            <DropDownUser>
              <div className="flex flex-col items-center justify-center">
                <div className="flex flex-row w-full justify-start items-center">
                  <Image height={34} width={34} src={profile?.avatarPath || '/assets/images/avatar.svg'}></Image>
                  <div className="flex flex-1 flex-col mx-[8px] min-w-[100px]">
                    <span className="text-[14px]">{_.startCase(profile?.name) || 'User'}</span>
                    {!(trialDays > 0) ? null : (
                      <span className="text-xs font-satoshi text-[#FFA500]">
                        Your trial ends in {trialDays} day{`${trialDays > 1 ? 's' : ''}`}
                      </span>
                    )}
                    {trialDays == 0 && <span className="text-xs font-satoshi text-[#FFA500]">Payment need update</span>}
                  </div>
                  <div>
                    <i className="bx bx-chevron-down"></i>
                  </div>
                </div>
              </div>
            </DropDownUser>
          )}
          render={({ setOpen }) => (
            <DropdownUser>
              <Link href={getRoute('settings.profile')}>
                <li
                  className="flex items-center py-[8px] px-[15px] hover:bg-[#f8f8f8] cursor-pointer"
                  onClick={() => setOpen(false)}
                >
                  <span className="icon">
                    <i className=" leading-8 text-[18px] bx bx-user"></i>
                  </span>
                  <span className="ml-[5px]">Profile</span>
                </li>
              </Link>
              <Link href={getRoute('settings.billing')}>
                <li
                  className="flex items-center py-[8px] px-[15px] hover:bg-[#f8f8f8] cursor-pointer"
                  onClick={() => setOpen(false)}
                >
                  <span className="icon">
                    <i className="leading-8 bx text-[18px] bx-dollar-circle"></i>
                  </span>
                  <span className="ml-[5px]">Billing</span>
                </li>
              </Link>
              <li
                className="flex items-center py-[8px] px-[15px] hover:bg-[#f8f8f8] cursor-pointer"
                onClick={() => handleLogout(() => setOpen(false))}
              >
                <span className="icon">
                  <i className="leading-8 text-[18px] bx bx-exit"></i>
                </span>
                <span className="ml-[5px]">Logout</span>
              </li>
            </DropdownUser>
          )}
        />
      </DashboardRightMenu>
    </DashboardHead>
  )
}
