import { getRoute, useRouter } from '@hook/router'
import Cookie from '@lib/cookie'
import { getProfile } from '@api/profile'

/**
 * get user from api doctor
 * @param {*} ctx
 * @param {*} acceptRole
 * @param {*} aceptStatus
 */

/**
 * Handling only unauthenticated
 * @param {*} ctx
 */

export async function handle(ctx) {
  const Route = useRouter(ctx)
  const cookie = new Cookie(ctx)
  const authToken = cookie.get('--app-auth-token')
  if (authToken) {
    Route.push(getRoute('dashboard.home'))
  }
}
