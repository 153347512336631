/**
 * Caling middleware
 * @param {*} ctx
 * @param {*} name
 * @param  {...any} props
 */
export const useMiddleware = async (ctx, name, ...props) => {
  let calledMiddleware
  try {
    calledMiddleware = await require(`./${name}.js`)
  } catch (e) {
    throw e
  }
  return calledMiddleware.handle(ctx, ...props)
}

export const useLayout = async (name, ...props) => {
  let Layout
  try {
    Layout = await require(`../../components/layouts/${name}.jsx`)
    return <Layout {...props} />
  } catch (e) {
    throw e
  }
}

/**
 * Handling dinamic middleware
 * @param {*} PageComponent
 * @param {*} parseMiddleware
 */
export const applyMiddleware = (PageComponent, parseMiddleware) => {
  const Wrapper = props => {
    return <PageComponent {...props} />
  }
  if (PageComponent.getLayout) {
    Wrapper.getLayout = PageComponent.getLayout
  }

  let PageContent = Wrapper

  Wrapper.getInitialProps = async ctx => {
    try {
      ctx.useMiddleware = (name, ...props) => useMiddleware(ctx, name, ...props)
      ctx.useLayout = async (name, ...props) => {
        const LayoutWrapper = await useLayout(name, ...props)
        PageContent = (
          <LayoutWrapper>
            <Wrapper />
          </LayoutWrapper>
        )
      }
      if (parseMiddleware) await parseMiddleware(ctx)
      const componentProps = PageComponent.getInitialProps && (await PageComponent.getInitialProps(ctx))
      return { ...componentProps }
    } catch (e) {
      throw e
    }
  }

  return PageContent
}
