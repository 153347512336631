import styled from 'styled-components'
import { FormError } from '@style/components/form'
import _ from 'lodash'

export const FormTextareaStyle = styled.textarea`
  padding: ${props => props.theme.sizes.sm};
  border: 1px solid ${props => props.theme.colors.grey40};
  width: 100%;
  display: block;
  font-size: ${props => props.theme.sizes.md};
  border-radius: 14px;
  min-height: 100px;
  resize: none;
  :placeholder {
    background-color: ${props => props.theme.colors.grey20};
  }
  :disabled {
    ${props => props.theme.colors.grey30};
  }
`

const Textarea = ({
  className,
  onChange = () => {},
  style,
  name = 'input-name',
  type,
  form,
  withError = false,
  placeholder = '',
}) => {
  const error = form?.errors[name]
  const touched = form.touched[name]
  return (
    <>
      <FormTextareaStyle
        style={style}
        className={className}
        name={name}
        type={type}
        onChange={e => {
          form?.setFieldValue(name, e.currentTarget.value)
          onChange(e)
        }}
        onFocus={e => form?.setFieldTouched(name, true)}
        onBlur={form?.handleBlur}
        value={(form?.values && form?.values[name]) || ''}
        placeholder={placeholder}
      />
      {error && withError && touched && (
        <FormError className="error-input">{_.upperFirst(error.replaceAll('_', ' ').replace('md ', ''))} </FormError>
      )}
    </>
  )
}

export default Textarea
