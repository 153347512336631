import { getRoute, matchRoute } from '@/hooks/router'
import {
  DashboadMenuContent,
  DashboardHeader,
  DashboardLogo,
  DashboardMenu,
  DashboardMenuIcon,
  DashboardMenuItem,
  DashboardMenuName,
  DashboardMenuSubItem,
  DashboardSide,
} from '@style/layouts/dashboard'
import Image from 'next/image'
import Link from 'next/link'
import { useDispatch, useSelector } from 'react-redux'
import { toggleMenu } from '@action/app'

export default function Sidebar() {
  const Dispatch = useDispatch()
  const isMenuExpand = useSelector(state => state.app?.menu?.expand)
  return (
    <DashboardSide className={`border-[1px] ${isMenuExpand && 'collapseDashboard'}`}
                   style={{ justifyContent: 'space-between' }}>
      <div>
        <DashboardHeader>
          <Link href={getRoute('dashboard.home')}>
            <DashboardLogo>
              <Image
                alt='logo'
                src={isMenuExpand ? '/assets/images/logo-mini.svg' : '/assets/images/logo.svg'}
                height={50}
                width='100%'
              />
            </DashboardLogo>
          </Link>
        </DashboardHeader>

        <DashboardMenu>
          <DashboardMenuItem className={matchRoute('site.all', true)}>
            <Link href={getRoute('site.all')}>
              <DashboadMenuContent>
                <DashboardMenuIcon>
                  <i className='bx bx-sitemap'></i>
                </DashboardMenuIcon>
                <DashboardMenuName>
                  <span>Sites</span>
                </DashboardMenuName>
              </DashboadMenuContent>
            </Link>
          </DashboardMenuItem>
        </DashboardMenu>

        <DashboardMenu>
          <DashboardMenuItem className={matchRoute('credential.index', true)}>
            <Link href={getRoute('credential.index')}>
              <DashboadMenuContent>
                <DashboardMenuIcon>
                  <i className='bx bx-key'></i>
                </DashboardMenuIcon>
                <DashboardMenuName>
                  <span>Credentials</span>
                </DashboardMenuName>
              </DashboadMenuContent>
            </Link>
          </DashboardMenuItem>
        </DashboardMenu>
        <DashboardMenu>
          <Link href={getRoute('settings.index')}>
            <DashboardMenuItem>
              <DashboadMenuContent>
                <DashboardMenuIcon>
                  <i className='bx bx-user'></i>
                </DashboardMenuIcon>
                <DashboardMenuName>
                  <span>Personal Settings</span>
                </DashboardMenuName>
              </DashboadMenuContent>
            </DashboardMenuItem>
          </Link>
          {matchRoute('settings.index') === 'active' && (
            <>
              <DashboardMenuSubItem className={matchRoute('settings.index', true)}>
                <Link href={getRoute('settings.index')}>
                  <span className={!isMenuExpand ? 'pl-[54px]' : 'm-auto'}>Profile</span>
                </Link>
              </DashboardMenuSubItem>
              <DashboardMenuSubItem className={matchRoute('settings.billing', true)}>
                <Link href={getRoute('settings.billing')}>
                  <span className={!isMenuExpand ? 'pl-[54px]' : 'm-auto'}>Billing</span>
                </Link>
              </DashboardMenuSubItem>
            </>
          )}
        </DashboardMenu>
      </div>
      <div style={{ marginBottom: 16, width: '100%' }}>
        <div
          onClick={() => Dispatch(toggleMenu())}
          style={{
            display: 'flex',
            marginBottom: 16,
            justifyContent: 'center',
            alignItems: 'center',
            right: 0,
            width: '100%',
            color: '#8A9099',
          }}
        >
          {isMenuExpand ? (
            <DashboardMenuIcon>
              <i className='bx bx-chevron-right' style={{ fontSize: 28, display: 'flex' }} />
            </DashboardMenuIcon>
          ) : (
            <>
              <DashboardMenuIcon>
                <i className='bx bx-chevron-left' style={{ fontSize: 28, display: 'flex' }} />
              </DashboardMenuIcon>
              <DashboardMenuName>
                <span style={{ fontSize: 14, color: '#3F434A', cursor: 'pointer' }}>Collapse</span>
              </DashboardMenuName>
            </>
          )}
        </div>
      </div>

      {/* <ul className="list-group">
        <li className="list-group-item">
          <Link href={getRoute('dashboard.home')}>
            <a>Dashboard</a>
          </Link>
        </li>
        <li className="list-group-item">
          <Link href={getRoute('site.all')}>
            <a>Sites</a>
          </Link>
        </li>
        <li className="list-group-item">
          <Link href={getRoute('credential.index')}>
            <a>Credentials</a>
          </Link>
        </li>
        <li className="list-group-item">
          <Link href={getRoute('dashboard.home')}>
            <a>Profile</a>
          </Link>
        </li>
      </ul> */}
    </DashboardSide>
  )
}
