import { Dashboard, DashboardMain, DashboardWrap } from '@style/layouts/dashboard'
import Sidebar from '@layout/sidebar'
import Header from '@layout/header'
import { useSelector, useDispatch } from 'react-redux'
import PopupWindow, { Modal } from '@component/utils/modal'
import ChooseSite from '@component/modals/site/choose-site'
import NewVersion from '@component/notif/new-version'
import LogButton from './LogButton'
import { openPopup } from '@action/menu'
import RegisterChange from '@page/overview/modals/register-change'
import { getSiteDetailSelector } from '@service/selectors/site'
import { useEffect, memo } from 'react'

function DashboardLayout({ children }) {
  const isMenuExpand = useSelector(state => state.app?.menu?.expand)
  const Dispatch = useDispatch()
  const [siteDetail] = useSelector(getSiteDetailSelector)

  return (
    <>
      <NewVersion />
      <Dashboard>
        {siteDetail?.name ? <LogButton onClick={() => Dispatch(openPopup('register-change'))} /> : ''}
        <Sidebar />
        <DashboardMain className={isMenuExpand && 'expand'}>
          <Header />
          <DashboardWrap>{children}</DashboardWrap>
        </DashboardMain>
      </Dashboard>
      {/* GLobal popup */}
      <PopupWindow name="choose-site">
        <Modal>
          <ChooseSite />
        </Modal>
      </PopupWindow>
      <PopupWindow name="register-change">
        <Modal alignItems="flex-end" justifyContent="flex-end" top="-10vh" left="-2vw">
          <RegisterChange />
        </Modal>
      </PopupWindow>
    </>
  )
}

export default memo(DashboardLayout)
