import styled from 'styled-components'

// main
export const Form = styled.form``

export const Group = styled.div`
  padding: 10px 0;
  position: relative;
  .iti--allow-dropdown {
    width: 100%;
  }
`

export const InputGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const FormInputGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const Label = styled.label`
  display: block;
  font-size: ${props => props.theme.sizes.md};
  margin-bottom: 8px;
  color: ${props => props.theme.colors.grey9};
  sup {
    color: ${props => props.theme.colors.red};
  }
`
export const FormInput = styled.input`
  padding: ${props => props.theme.sizes.md};
  background: ${props => props.theme.colors.grey30};
  border: 1px solid ${props => props.theme.colors.grey9};
  width: 100%;
  display: block;
  font-size: ${props => props.theme.sizes.md};
  border-radius: 10px;
  :placeholder {
    background-color: ${props => props.theme.colors.grey20};
  }
`
export const FormSelect = styled.select`
  padding: ${props => props.theme.sizes.md};
  background: ${props => props.theme.colors.grey30};
  border: 1px solid ${props => props.theme.colors.grey40};
  border-radius: 10px;
  width: 100%;
  display: block;
`

export const GroupContent = styled.div`
  max-width: 117px;
  flex: 0 0 117px;
`

export const InputGroupInput = styled.div`
  max-width: calc(100% - 117px);
  flex: 0 0 calc(100% - 117px);
  input {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
`

export const FormTitle = styled.h3`
  color: ${props => props.theme.colors.grey10};
  font-size: ${props => props.theme.sizes.xxl};
  font-weight: 400;
`

export const FormRadioStyle = styled.input`
  display: none;
  cursor: pointer;
  :checked + label {
    ::before {
      background-color: ${props => props.theme.colors.white};
      height: 10px;
      width: 10px;
      border: 5px solid ${props => props.theme.colors.primary};
    }
  }
`
export const FormCheckStyle = styled.input`
  display: none;
  cursor: pointer;
  & + label::before {
    border-radius: 1px !important;
  }
  :checked + label {
    ::before {
      background-image: url(/assets/icons/bx-check.svg);
      background-repeat: no-repeat;
      background-size: 22px;
      background-color: ${props => props.theme.colors.primary};
      border: 1px solid ${props => props.theme.colors.primary};
    }
  }
`

export const FormRadioInputStyle = styled.div`
  display: flex;
  align-items: center;
  :not(:first-child) {
    margin-left: 43px;
  }
`

export const FormRadioLabelStyle = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  ::before {
    content: '';
    height: 18px;
    width: 18px;
    margin-right: ${props => (props.label ? '10px' : '0px')};
    background-size: 12px;
    background-position: center;
    display: flex;
    overflow: hidden;
    background-color: ${props => props.theme.colors.grey30};
    border: 1px solid ${props => props.theme.colors.grey40};
    border-radius: 100%;
    margin-right: 10px;
  }
`

export const FormRadioGroup = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 50px;
`

export const FormError = styled.span`
  color: ${props => props.theme.colors.danger};
  display: block;
  width: 100%;
  /* font-style: italic; */
  font-size: 0.7rem;
  margin-top: 5px;
`

export const InpputFileWrap = styled.label`
  align-items: center;
  position: relative;
  border-radius: 10px !important;
  overflow: hidden;
  ${props =>
    !props.customContent &&
    `
    padding: 23px 20px;
    border: 1px dashed ${props.theme.colors.grey25};
    display: flex;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
  `}
  input {
    cursor: pointer;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 9;
    opacity: 0;
    top: 0;
    left: 0;
  }
  i {
    font-size: 25px;
    color: ${props => props.theme.colors.grey5};
  }
`

export const InputFileName = styled.span`
  padding-left: 20px;
  display: flex;
  flex-direction: column;
`

export const InputImageWrapper = styled.div`
  flex: 40px;
  max-width: 40px;
  img {
    height: 40px;
    object-fit: cover;
    border-radius: 100%;
    width: 40px;
  }
`

export const InputFileLabel = styled.span`
  font-weight: 500;
  sup {
    color: ${props => props.theme.colors.red};
  }
`

export const InputFileDesc = styled.span`
  color: ${props => props.theme.colors.grey5};
  font-size: ${props => props.theme.sizes.xs};
`

// wrapper form
export const FormWrapper = styled.div`
  ${props =>
    props.readOnly &&
    `
    position: relative;
    ::after {
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: rgba(0,0,0,0);
      content: '';
      top: 0;
      left: 0;
    }
    button[type=submit] {
      display: none;
    }
  * {
    user-selection: none;
    pointer-events: none;
  }
  `}
`
