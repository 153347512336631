import { getRoute } from '@hook/router'
import Link from 'next/link'
import { useRouter } from 'next/router'

export default function SiteTab() {
  const { query } = useRouter()

  return (
    <ul className="nav nav-tabs mb-4">
      <li className="nav-item">
        <Link href={getRoute('site.detail', { id: query.id })}>
          <a className="dropdown-item" href="#">
            Overview
          </a>
        </Link>
      </li>
      <li className="nav-item">
        <Link href={getRoute('site.filter', { id: query.id })}>
          <a className="dropdown-item" href="#">
            Filters
          </a>
        </Link>
      </li>
    </ul>
  )
}
